<router-outlet></router-outlet>
<alc-splash-screen></alc-splash-screen>
<ng-template #customBusyTemplate let-options="options">
  <div class="custom-template">
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xml:space="preserve">
      <rect x="3" y="2" width="3" height="20" />
      <rect x="8" y="2" width="3" height="20" />
      <rect x="13" y="2" width="3" height="20" />
      <rect x="18" y="2" width="3" height="20" />
    </svg>
  </div>
</ng-template>
